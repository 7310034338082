<template>
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</template>

<script type="text/javascript">

export default {
  name: 'Loader'
}
</script>
<style scoped>
.loader-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F8FAF990;
}

.loader{
  border: 5px solid;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-color: #03A84E transparent transparent;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes spin{
  100% {
    transform:rotate(360deg)
  }
}
</style>
